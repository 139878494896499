import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Alert, Row, Col, Accordion } from 'react-bootstrap';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const AFKGuidesReroll: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_reroll.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Reroll guide</h1>
          <h2>
            Start your adventure in AFK Journey with the best character using
            our Reroll Guide.
          </h2>
          <p>
            Last updated: <strong>26/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Is it worth it to reroll?" />
        <Alert variant="primary alert-red">
          <p>
            While the server-hopping method of rerolling is save,{' '}
            <strong>
              if you create too many new accounts via emulator you might get
              your accounts flagged and banned
            </strong>
            . It happens from time to time in AFK Arena and Dislyte, so be aware
            of it.
          </p>
        </Alert>
        <p>
          That's the most important question as normally in idle games,
          rerolling isn't worth because those types of games heavily rely on
          dupes, and{' '}
          <strong>
            AFK Journey isn't an exception unless you want to heavily min-max
            who you will start with
          </strong>
          . Here's why:
        </p>
        <ul>
          <li>
            The first 2 pities you reach on the normal banner are 'fixed' when
            it comes to the <AFKItem name="S-Level" /> characters you can
            obtain:
          </li>
          <ul>
            <li>
              The <strong>first pity</strong> (10 pulls) guarantees you probably
              the strongest story damage dealer in the game{' '}
              <AFKCharacter mode="inline" slug="cecia" enablePopover /> who also
              can be used with success in other modes, but she will drop off
              there in late game (due to being too slow),
            </li>
            <li>
              The <strong>second pity</strong> (20 pulls) guarantees you one of
              the 3 strongest Healers / Supports (equal chance to get each of
              them): <AFKCharacter mode="inline" slug="rowan" enablePopover />{' '}
              <AFKCharacter mode="inline" slug="hewynn" enablePopover />{' '}
              <AFKCharacter
                mode="inline"
                slug="smokey-and-meerky"
                enablePopover
              />
            </li>
          </ul>
          <li>
            The 'fixed' pulls were with us for the whole duration of PTR and
            Beta Voyage and we're assuming they will be carried over to the
            official release. If anything changes, we will update the guide.
          </li>
        </ul>
        <p>
          Still, this doesn't mean that rerolling isn't at all worth it and
          those who want to min-max their start might want to consider doing it
          anyway. Here's why:
        </p>
        <ul>
          <li>
            A single copy of a great <AFKItem name="S-Level" /> can easily carry
            you for the first week or two, making the progression through the
            early game much easier and less frustrating,
          </li>
          <ul>
            <li>
              Some characters can even function on a single copy all the way to
              the endgame - mainly supports and some tanks,
            </li>
            <li>
              Also, the Ascension system <strong>has been simplified</strong>{' '}
              compared to other games in the genre and you need less dupes to
              raise a character to final tier. And no, this doesn't mean the
              characters are harder to obtain, as the pity system is pretty
              great and you also can buy dupes of characters in various shops,
            </li>
          </ul>
          <li>
            While the fixed pity gives you a great DPS and Support / Healer,
            you're still lacking a tank - and we all know that if the frontline
            dies, the whole team dies. While the game will give you{' '}
            <AFKCharacter mode="inline" slug="lucius" enablePopover /> for free,
            he falls off pretty quickly when it comes to the main tank role and
            instead becomes off-tank / shield bot,{' '}
          </li>
          <li>
            When it comes to <AFKItem name="S-Level" /> tanks,{' '}
            <AFKCharacter mode="inline" slug="thoran" enablePopover /> and
            <AFKCharacter
              mode="inline"
              slug="granny-dahnie"
              enablePopover
            />{' '}
            are considered the best tanks in the game and they will make your
            early progression smoother.
          </li>
          <li>
            On top of the guaranteed pity characters, you might get random
            <AFKItem name="S-Level" /> characters before reaching the second
            pity and what's best{' '}
            <strong>the pity counter won't reset if this happens</strong> (yes,
            it was a big surprise to us too when it happened to use as we tested
            the system). Though, once you reach the second pity, the pity system
            goes back to 'normal' and if you obtain an S-level character
            earlier, the pity will reset,
          </li>
          <li>
            Rerolling takes only <strong>roughly 10 minutes</strong>.
          </li>
        </ul>
        <p>Still, it's not all sunshine and rainbows.</p>
        <p>
          Rerolling on your{' '}
          <strong>phone is limited to 'server-hopping'</strong> (this means that
          you won't be able to play on the latest server to start on an even
          ground) or 'email-hopping'. And on top of that, while the game has a
          PC client and you can create a Guest account there, you have to
          uninstall the game and remove all traces of the game to be able to
          create a new guest account. This leaves you with{' '}
          <strong>
            emulators that actually make the whole process much easier and
            faster
          </strong>
          , but more about it later.
        </p>
        <SectionHeader title="Reroll methods" />
        <p>
          The first thing you will need to prepare if you plan to reroll is your
          phone/PC or an emulator. Below you can find a comparison of each
          method.
        </p>
        <Row xs={1} xl={2} xxl={2} className="reroll-paths">
          <Col>
            <div className="box">
              <div className="option phone">
                <h6>Phone / PC client reroll</h6>
              </div>
              <div className="inside">
                <p>
                  While AFK Journey has a <strong>Guest System</strong>{' '}
                  available on the Android/iOS devices, we're not sure{' '}
                  <strong className="red">
                    clearing cache on your Android will delete your Guest
                    Account
                  </strong>
                  . As for the PC client - the Guest Account syste exist there,
                  but there's no easy way to delete it without uninstalling the
                  whole game.
                </p>
                <p>
                  This leaves you with two options:{' '}
                  <strong>server-hopping and email-hopping</strong> (and they
                  can also be combined):
                </p>
                <ul>
                  <li>
                    <strong>Email-hopping</strong> method is pretty
                    straightforward. You have to prepare a bunch of Google
                    emails, login with one, then go through the reroll process
                    on the latest server. Once you don't get what you want, you
                    login with another email and try again,
                  </li>
                  <ul>
                    <li>
                      We're not sure if the <strong>Salt Method</strong> will
                      work with this game as we were not able to test it on the
                      PTR.
                    </li>
                  </ul>
                  <li>
                    <strong>Server-hopping</strong> method is a variation of the
                    above. Instead of logging off after rerolling on the latest
                    server, you switch the server to an older one - and rinse
                    and repeat the whole process.
                  </li>
                  <ul>
                    <li>
                      The issue with this method is that the longer it will take
                      you to hit the jackpot,{' '}
                      <strong>
                        the further behind you will be the other players on the
                        server
                      </strong>{' '}
                      - this will affect both Dream Realm (bossing content with
                      ranking system) and PVP.
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </Col>
          <Col>
            <div className="box">
              <div className="option emulator">
                <h6>Emulator reroll</h6>
              </div>
              <div className="inside">
                <p>
                  Rerolling on emulators is the{' '}
                  <strong>preferred method of rerolling in AFK Journey</strong>.
                </p>
                <p>
                  Furthermore, if you have a decent PC and can handle multiple
                  instances at once. Since reaching the place where you can
                  reroll takes roughly 10 minutes, it's easy to synchronize your
                  instances and not lose your mind while you're doing it.
                </p>
                <p>
                  We suggest using{' '}
                  <strong>
                    BlueStacks for rerolling in AFK Journey as the game works
                    very well on it
                  </strong>{' '}
                  and allows you to easily reroll to start strong.
                </p>
                <p>Here's how the process works:</p>
                <ol>
                  <li>
                    Install BlueStacks from the link below this section and open
                    the Multi-Instance Manager,
                  </li>
                  <li>
                    Create a new instance and name it AFK Journey Master
                    Instance,
                  </li>
                  <li>
                    You can download AFk Journey either from the store or get an
                    APK from{' '}
                    <a
                      href="https://apkcombo.com/afk-journey/com.farlightgames.igame.gp/download/apk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>
                    . Simply drag the APK onto your instance to install the
                    game,
                  </li>
                  <li>
                    Install AFK Journey on the instance,{' '}
                    <strong>but don't launch the game</strong>. If you launch
                    it, your user ID will be stored on the instance and you
                    won't be able to use the multi-instance trick,
                  </li>
                  <li>
                    Clone the Master instance as many times as your PC can
                    handle (ie. if you can easily run 4 instances at the same
                    time, make 4 clones),
                  </li>
                  <li>
                    Open each of the cloned instances, download the initial
                    stuff, login as guest and start rolling (process explained
                    below),
                  </li>
                  <li>
                    You can use the <strong>Synchronize feature</strong> to
                    replicate your mouse movements between all instances,
                  </li>
                  <li>
                    If you don't get what you want,{' '}
                    <strong>simply clear the cache and start again.</strong>. If
                    clearing cache won't work, delete the instance and create
                    new ones via cloning.
                  </li>
                  <li>Rinse and repeat.</li>
                </ol>
                <p>
                  Below the section you can find a more detailed guide with
                  images - just press the arrow on the right side of the section
                  to expand it.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <p>And here's a detailed instruction for the emulator reroll:</p>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              BlueStacks Emulator detailed instruction
            </Accordion.Header>
            <Accordion.Body>
              <p>Here's how the emulator reroll process works:</p>
              <h6>
                1. Install BlueStacks via the above link and open the
                Multi-Instance Manager.
              </h6>
              <p>
                The feature is available in the bottom left corner of the main
                BlueStacks application after launching it - check the below
                image.
              </p>
              <StaticImage
                src="../../../images/reverse/generic/bs_reroll_1.jpg"
                alt="Reroll guide"
              />
              <h6>
                2. In the Multi-Instance window, click the blue Instance button
                in the bottom left corner.
              </h6>
              <StaticImage
                src="../../../images/reverse/generic/bs_reroll_2.jpg"
                alt="Reroll guide"
              />
              <ul>
                <li>
                  In the window that appears, pick Fresh Instance in the first
                  step,
                </li>
                <li>In the second step, we suggest picking Pie-64,</li>
                <li>
                  If you're planning to reroll using a single instance only,
                  then pick the best possible settings (for example 4-cores, 4GB
                  Ram), but in the case you want to try the multi-instance
                  reroll, pick 2-cores and 2GB Ram instead - this will allow you
                  to launch more instance at the same time,
                </li>
                <li>
                  After creating the instance, rename it to AFK Journey Master.
                </li>
              </ul>
              <h6>3. Launch the newly created instance.</h6>
              <h6>
                4. Open Play Store, login, and install AFK Journey or use APK
                instead of the game isn't available in your region.
              </h6>
              <h6>5. Now close the Master Instance.</h6>
              <p>
                <strong className="red">
                  The user ID cookie is created when you open the game
                </strong>
                , which means that you won't be able to multi-instance it later
                as it will think you're already logged in. So don't make the
                mistake of opening the game to download the updates.
              </p>
              <h6>
                6. Go back to the Multi-Instance manager and Clone the Master
                instance as many times you need.
              </h6>
              <StaticImage
                src="../../../images/reverse/generic/bs_reroll_5.jpg"
                alt="Reroll guide"
              />
              <h6>7. You're ready to start the the reroll hell!</h6>
              <p>
                Now you simply need to launch the cloned instance (or
                instances), reroll and when you don't get what you want{' '}
                <strong>simply clear the cache and start again.</strong>. If
                clearing cache won't work, delete the instance and create new
                ones via cloning.
              </p>
              <h5>
                7. Done with reroll? Bind your account so you don't lose it!
              </h5>
              <p>
                Also after binding you can start playing using the official PC
                Client.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <br /> <br />
        <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
        <SectionHeader title="Reroll process" />
        <h5>TL;DR</h5>
        <p>
          After launching the game and creating your character, you will have to
          go through the tutorial and roughly 5 minutes in, you will unlock the
          Summoning feature to do your first 10-pull. After playing for 5 more
          minutes, you should be able to gain enough{' '}
          <AFKItem name="Invite Letter" /> and <AFKItem name="Diamonds" /> to do
          20 more pulls, which will guarantee yourself 2x{' '}
          <AFKItem name="S-Level" /> characters.
        </p>
        <h5>Detailed reroll process</h5>
        <StaticImage
          src="../../../images/afk/generic/reroll_1.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 1.</strong> Launch the game. When the
          download prompt appears, choose the minimum setting (it will download
          roughly 200 MB) and then skip the animation by touching the screen for
          2 seconds. After skipping the conversation, the game will ask you to
          select your main character (male or female), then customize it.
        </p>
        <p>
          <strong className="red">
            Names are unique - so if you clear the cache/reroll on the same
            server, you won't be able to create a character with the same name.
          </strong>{' '}
          So use some dummy names and change to your desired name once you're
          done rolling.
        </p>
        <StaticImage
          src="../../../images/afk/generic/reroll_2.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 2.</strong> Go to <strong>Settings</strong>{' '}
          (press the bottom right corner Menu icon then the Cog icon). There
          head to Battle tab and in the Play Ultimate Animation section, select
          the Never option. This will save you some time during the reroll.
        </p>
        <StaticImage
          src="../../../images/afk/generic/reroll_3.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 3.</strong> Play the tutorial till the
          battle with the Twister at which point you will unlock the{' '}
          <strong>Noble Tavern</strong> and the game will force you to do a
          single pull tiwce. While the first one is fixed and you will always
          get <AFKCharacter mode="inline" slug="fay" enablePopover /> the second
          pull will give you a random <AFKItem name="A-Level" /> damage dealer
          character.
        </p>
        <StaticImage
          src="../../../images/afk/generic/reroll_4.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 4.</strong> Get 10{' '}
          <AFKItem name="Invite Letter" /> from the Journey gift and do a
          10-pull on the All-Hero Recruitment Banner (Epic Recruitment banner
          requires <AFKItem name="Epic Invite Letter" />
          ). This will give you{' '}
          <AFKCharacter mode="inline" slug="cecia" enablePopover /> as the first
          pity is set to 10 and is fixed. The pity will increase to 20 after
          that, but since you've done two single-pulls before, you will start at
          18 instead.
        </p>
        <StaticImage
          src="../../../images/afk/generic/reroll_8.jpg"
          alt="Guides"
        />
        <p>
          To access the <strong>Journey's Gift</strong>, press the 'gift' icon
          in the bottom right corner of the screen and you will obtain 10x{' '}
          <StaticImage
            src="../../../images/afk/icons/currency_summon.png"
            alt="Invite Letter"
          />{' '}
          <strong>Invite Letters</strong> as the day 1 login rewards.
        </p>
        <p>
          Sadly, the Wishlist for the All-Hero banner unlocks after doing 30
          pulls on it, so you can't really use during the tutorial.
        </p>
        <p>
          <strong color="green">Step 5.</strong> Before we continue, level your
          characters to level 10 in the Resonating Hall. Focus on{' '}
          <AFKCharacter mode="inline" slug="cecia" enablePopover /> as she will
          be your carry moving forward! Also, if you gather enough{' '}
          <AFKItem name="Essence" /> to ascend a character past level 10, again
          focus on Cecia first.
        </p>
        <StaticImage
          src="../../../images/afk/generic/reroll_6.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 6.</strong> Continue with the main story.
          After roughly 2-3 minutes, you will get a prompt that you've unlocked
          the <strong>Growth Path</strong>. The Growth Path system is a
          progression tracker that rewards you for completing various missions
          that will help you understand the game and show you what you should do
          next. The Stage 1 of the Growth Path will auto-complete and among
          other things you will obtain:
        </p>
        <ul>
          <li>
            2x <AFKItem name="Invite Letter" />
          </li>
          <li>
            1x <AFKItem name="Epic Invite Letter" />
          </li>
          <li>
            300x <AFKItem name="Diamonds" />
          </li>
          <li>
            <strong>60x Soulstone (A-level)</strong> - use them to obtain a
            random A-level character.
          </li>
        </ul>
        <StaticImage
          src="../../../images/afk/generic/reroll_7.jpg"
          alt="Guides"
        />
        <p>
          <strong color="green">Step 7.</strong> Continue with the main story.
          After roughly 5 minutes, you will reach Holistone, the first major
          city hub in the game. Talk with Hogan there to finish his quest and at
          this point you will also unlock the Mail feature where you will find
          the pre-register rewards:
        </p>
        <ul className="bigger-margin">
          <li>
            10x{' '}
            <StaticImage
              src="../../../images/afk/icons/currency_summon.png"
              alt="Invite Letter"
            />{' '}
            <strong>Invite Letters</strong>
          </li>
          <li>
            1000x{' '}
            <StaticImage
              src="../../../images/afk/icons/currency_diamond.png"
              alt="Diamonds"
            />{' '}
            <strong>Diamonds</strong>
          </li>
          <li>
            <AFKCharacter mode="inline" slug="lyca" enablePopover />
          </li>
          <li>1x Main character skin</li>
        </ul>
        <p>
          <strong color="green">Step 8.</strong> Use any active codes! You can
          find them here:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Promo codes"
            link="/afk-journey/codes"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_codes.jpg"
                alt="Promo codes"
              />
            }
          />
        </Row>
        <p>
          <strong color="green">Step 9.</strong> Time to pull! You should have
          enough resources to reach the next pity (20) milestone on the
          All-Recruitment banner:
        </p>
        <ul>
          <li>
            14x <AFKItem name="Invite Letter" />
          </li>
          <ul>
            <li>
              <strong>10</strong> from Pre-registration rewards,
            </li>
            <li>
              <strong>2</strong> from Growth Path Stage 1 completion,
            </li>
            <li>
              <strong>2</strong> from two Elite Chests you will find on your way
              to Holistone.
            </li>
          </ul>
          <li>
            4x <AFKItem name="Epic Invite Letter" />
          </li>
          <li>
            3000+ <AFKItem name="Diamonds" />
          </li>
        </ul>
        <p>
          After doing the 20 pulls, you will get your second{' '}
          <AFKItem name="S-Level" /> character. It will be either:
        </p>
        <ul className="bigger-margin">
          <li>
            <AFKCharacter mode="inline" slug="rowan" enablePopover />
          </li>
          <li>
            <AFKCharacter
              mode="inline"
              slug="smokey-and-meerky"
              enablePopover
            />
          </li>
          <li>
            <AFKCharacter mode="inline" slug="hewynn" enablePopover />
          </li>
        </ul>
        <p>All three of them are great Supports / Healers! </p>
        <SectionHeader title="Reroll targets " />
        <p>
          Honestly, since you're already starting with a great support, the only
          role that you could aim for in the reroll is a tank or another support
          (the one you didn't obtain from the second pity).
        </p>
        <p>
          For the role of damage dealer, we recommend rerolling for{' '}
          <AFKCharacter mode="inline" slug="florabelle" enablePopover />, who is
          a well rounded character who will easily carry you from early to late
          game.
        </p>
        <p>
          When it comes to tanks, the best one is{' '}
          <AFKCharacter mode="inline" slug="thoran" enablePopover /> who is
          considered the pinnacle of tanking in AFK Journey. Not only he can
          cheat death once, but also can also deal a lot of damage - sometimes
          even one shooting the enemy tank. He becomes even stronger with his EX
          weapon as he can form a pact with an ally, taking damage instead of
          him and in exchange getting healed when the ally deals damage.
        </p>
        <p>
          We can opt to roll for both, and try to make a team with at least 3
          characters from the same faction, or focus on one of them and build a
          team tailored to their faction.
        </p>
        <p>
          Learn more about the meta teams and characters in our other guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tier List"
            link="/afk-journey/tier-list"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_tier.jpg"
                alt="Tier List"
              />
            }
          />
          <CategoryCard
            title="Team building (beginner)"
            link="/afk-journey/guides/team-building"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_team.jpg"
                alt="Team building"
              />
            }
          />
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesReroll;

export const Head: React.FC = () => (
  <Seo
    title="Reroll guide | AFK Journey | Prydwen Institute"
    description="Start your adventure in AFK Journey with the best character using our Reroll Guide."
    game="afk"
  />
);
